.pagination {
  .page-item.active {
    .page-link {
      background: $primaryManColor;
      background: linear-gradient(90deg, $primaryManColor 0%, rgba(1, 73, 88, 1) 100%);
      color: #fff;

      body.woman & {
        background-color: $primaryWomanColor;
        border-color: $primaryWomanColor;
      }

    }
  }

  .page-link {
    color: $primaryManColor;

    body.woman & {
      color: $primaryWomanColor;
    }
  }
}