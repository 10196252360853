.rnew-products-list {
  .photo-box {
    width: 100px;
    display: inline-flex;
    border: 1px solid #dedede;
    border-radius: 5px;
    overflow: hidden;
    height: 100px;
    justify-content: center;
    align-items: center;
    background: #fff;
  }

  .badges {
    display: block;
    margin-top: 5px;
  }

  .diagnostic-codes-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}