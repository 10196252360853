.section-bar {
  a {
    color: #a8a8a8;
    padding: 10px;
    border-right: 1px solid #a8a8a8;
    transition: .2s all;

    &:hover {
      color: #000;
    }

    &:last-child {
      border-right: 0;
    }
  }

  .section-bar-left {
    display: flex;
    align-items: center;
  }

  .section-bar-right {
    display: flex;
    align-items: center;
  }

  .ui-sortable-handle {
    cursor: move;
  }
}

.auction-editor {
  .auction-section {
    text-align: center;
  }

  .m-icon {
    color: #777;
    display: inline-block;
    font-size: 20px;
    font-weight: normal;
    height: 32px;
    line-height: 31px;
    vertical-align: middle;
    width: 32px;
    margin: 0 5px;
  }

  > ul {
    background: #fff;
    padding: 5px 0 !important;
    border-radius: 3px;
  }

  li.item-editor {
    color: #7f7f7f;
    border: 1px solid rgb(212, 212, 212);;
    padding: 20px 10px;
    background: rgb(212, 212, 212);
    cursor: default !important;
    margin-bottom: 10px;

    textarea {
      display: none;
    }

    .section-bar {
      display: none;
    }
  }

  li.item-editor.active {
    border: 1px solid #ddd;
    background: #fff;

    .auction-preview {
      display: none;
    }

    textarea {
      display: block;
    }

    .section-bar {
      justify-content: space-between;
      display: flex;
      align-content: center;
      background: #eee;
      border: 1px solid #ddd;
      line-height: 1;
      margin: -21px -11px 10px -11px;
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .add-new-section {
    width: 100%;
    color: #fff;
    background: $primaryManColor;

    body.woman & {
      background: $primaryWomanColor;
    }

    span {
      margin-left: 10px;
    }
  }

  .mce-statusbar {
    display: none !important;
  }

  .mce-tinymce {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .mce-panel {
    border: 0 solid #ccc;
    background-color: #f0f0f0;
    background-image: none;
    filter: none;
  }

  .mce-btn {
    border: none transparent;
    text-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    filter: none;
  }

  img {
    max-width: 100%;
  }
}

.auction-edytor-svg-icon {
  fill: #08C;
  height: 20px;
  vertical-align: -4px;
  margin-right: 5px;
}

.auction-preview {
  color: #444;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  text-align: left;

  h1 {
    display: block;
    font-family: Roboto, Open Sans, sans-serif;
    font-weight: 500;
    margin: 18px 0;
    font-size: 24px;
    line-height: 24px;
  }

  h2 {
    display: block;
    font-family: Roboto, Open Sans, sans-serif !important;
    font-weight: 500 !important;
    margin: 18px 0 !important;
    font-size: 18px !important;
    line-height: 18px !important;
    text-align: left !important;
    color: #444 !important;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
    padding-left: 40px;
    margin: 16px 0;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
    padding-left: 40px;
    margin: 16px 0;
  }

  p {
    margin: 16px 0;
    word-wrap: break-word;
  }
}

#cms-file-modal {
  .modal-dialog {
    max-width: 860px;
    width: auto;
  }
}

.photo-section {
  width: 100%;
  height: 200px;
  background: #eee;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 900;
    content: '\f302';
    font-size: 2em;
  }
}

.photo-form {
  .input-group {
    margin-bottom: 10px;
  }
}

.photo-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: none;
  }

  &.filled {
    img {
      display: block;
    }

    .photo-section {
      display: none;
    }
  }
}

.preview {
  min-height: 500px;
  margin-bottom: 15px;

  li {
    list-style-position: inside;
  }

  img {
    margin: 0 10px;
  }
}

.allegro-photos-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px;

  .photo {
    width: 100px;
    margin: 10px;

    .photo-image {
      width: 100%;
      height: auto;
    }
  }
}