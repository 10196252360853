.inventory-info {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .inventory-info-item {
    padding: 10px;
  }

  .badge {
    margin-top: 0;
  }
}

.inventory-info-badge {
  display: flex;
  align-items: center;
  justify-content: center;

  .inventory-info-badge-item {
    padding: 35px;

    .badge-count {
      font-size: 48px;
      display: block;
      font-weight: 900;
    }
  }
}