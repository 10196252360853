.rnew-sidebar {
  background: $primaryManColor;
  background: linear-gradient(90deg, $primaryManColor 0%, rgba(1, 73, 88, 1) 100%);

  body.woman & {
    background: $primaryWomanColor;
  }

  .brand-link {
    body.woman & {
      border-bottom: 1px solid #ff87dc;
    }
  }

  .rnew-sidebar-content {
    padding: 0;
    height: calc(100% - 7rem - 1px);
  }

  .nav-link.rnew-navbar-link {
    background: none;
    border-radius: 0;
    font-size: .875em;
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    .nav-link {
      &.active {
        box-shadow: none;
      }
    }
  }

  .rnew-sidebar-list {

    .active {
      .rnew-navbar-link {
        box-shadow: none;
        color: #fff;
        background: rgb(246, 93, 44);
        background: linear-gradient(90deg, $secondaryManColor 0%, #ca3a71 100%);

        body.woman & {
          background-color: $secondaryWomanColor;
        }

        &:hover {
          color: #fff;
          background: rgb(246, 93, 44);
          background: linear-gradient(90deg, $secondaryManColor 0%, #ca3a71 100%);
          transition: all .5s ease-in;

          body.woman & {
            background-color: $secondaryWomanColor;
          }
        }
      }
    }
  }

  .rnew-user-panel {
    overflow: visible;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;

    body.woman & {
      border-bottom: 1px solid #ff87dc;
    }

    .rnew-user-name {
      color: #fff;
    }

    .rnew-user-role {
      font-size: .750em;
      color: #c2c7d0;
      margin: 0;
    }
  }
}

.rnew-profile-slider {
  width: 100%;
  margin-top: 25px;
  display: none;

  .rnew-profile-slider-item {
    a {
      display: block;
      width: 100%;
      font-size: .875em;
      border-radius: .25rem;
      padding: .5rem 1rem;

      &:hover {
        background: #313131;
        color: #fff;
      }

      p {
        margin-bottom: 0;
      }

      .nav-icon {
        float: left;
        margin-top: 3px;
        margin-right: 10px;
        color: #fff;
      }
    }
  }
}

.navbar-item:not(.menu-open) .rnew-navbar-link:hover {
  transition: all .2s ease-in;
}