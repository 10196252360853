.delivery-info {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .delivery-info-item {
    padding: 10px;
  }

  .badge {
    margin-top: 0;
  }
}

.price-edit-iframe,
.delivery-info-summary {
  width: 100%;
  height: 555px;
  border: 0;
}

.table-controls {
  margin-top: 25px;
  padding: 0 10px;
}

.uploader {
  margin-top: 35px;

  h6 {
    padding: 35px 15px 0 15px;
  }

  .deliveries-uploader {
    padding: 35px;
    font-size: 12px;
  }

  .deliveries-uploader-form {
    margin: 35px auto;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    .form-group {
      margin: 0 10px;

      button {
        font-size: 14px;
      }
    }
  }

  .deliveries-uploader-drop-section {
    min-height: 100px;
    border: 2px dashed #e0e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.summary-wrapper,
.subdeliveries-wrapper,
.custom-sku-stock-count-wrapper
 {
  display: none;
  width: 100%;
}

.global-custom-sku-stock-count-wrapper
 {
  width: 100%;
}
.delivery-tab {
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px;
  padding: 0 5px;
  margin-top: 25px;

  .delivery-tab-content {
    padding: 10px 5px;
    width: 100%;
    border-bottom: 1px solid #dedede;
    display: block;
    color: #000;
    margin-bottom: 25px;

    &.active {
      font-weight: 700;
    }
  }

  .controls {
    padding: 0;

    .btn {
      margin-right: 10px;
    }
  }

  .subdelivery-wrapper,
  .delivery-wrapper {
    padding: 0;
    display: none;
    margin-bottom: 25px;
    width: 100%;
  }
}

.new-subdelivery-btn {
  margin-right: 10px;
}

.change-delivery-status-form .select2 {
  min-width: 200px;
  margin-right: 10px;
}