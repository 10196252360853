@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import '~jstree-bootstrap-theme/dist/themes/proton/style.min.css';

body {
  background-color: lightgray;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

@import "variables";
@import "mixins";
@import "buttons";
@import "icons";


h1, h2, h3, h4, h5, h6 {
  font-family: 'Exo2', sans-serif;
  color: $primaryManColor;
  font-weight: 700;

  body.woman & {
    color: $primaryWomanColor;
  }
}

@import "modules/layout";
@import "modules/form";
@import "modules/alerts";
@import "modules/table";
@import "modules/modal";
@import "modules/pagination";
@import "modules/badges";
@import "modules/progress";

@import "partials/header";
@import "partials/sidebar";
@import "partials/footer";

@import "partials/User/login";
@import "partials/User/update";

@import "partials/ParamsEditor/update";

@import "partials/Manufacturer/update";

@import "partials/Models/create";
@import "partials/Models/update";

@import "modules/auctions";
@import "partials/Models/list";

@import "partials/Product/create";
@import "partials/Product/info";
@import "partials/Product/list";
@import "partials/Product/dragndrop";

@import "partials/ProductParams/update";

@import "partials/Deliveries/update";
@import "partials/Deliveries/list";
@import "partials/Deliveries/info";

@import "partials/Refurbish/refurbish";

@import "partials/Basket/list";
@import "partials/Basket/update";

@import "partials/Warehouse/info";
@import "partials/Warehouse/product-to-shelf";
@import "partials/Warehouse/move-to-cart";
@import "partials/Warehouse/analyse";

@import "partials/Logs/list";

@import "partials/Sku/update";
@import "partials/Sku/kadlubki";

@import "modules/action";

@import "partials/Inventory/process";
@import "partials/Inventory/analyse";
@import "partials/Inventory/report";

@import "partials/Main/main";

.select2-container .select2-selection--single {
  height: 40px;
}

.select2.select2-container {
  width: auto !important;
  margin-left: 10px;
  min-width: 200px;
  margin-right: 10px;
}

.select2.warehouse-select {
  margin-right: 10px;
}

.select2.warehouse-select,
.select2.aisles-select {
  width: 250px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
}

.hint {
  padding: 15px;
  background: #dedede;
  font-size: 12px;
}

.breadcrumb {
  font-size: 14px;

  a {
    color: #6c757d;
  }

  .active {
    font-weight: 700;
  }
}