.product-info,
.product-sku-info {
  .rnew-product-info-list,
  .rnew-test-result-info-list,
  .rnew-battery-info-list,
  .rnew-sku-info-list {
    padding: 0;
    list-style-type: none;

    .rnew-product-info-item,
    .rnew-battery-info-item {
      padding: 10px;
    }

    .rnew-product-info-item {
      .rnew-primary-button {
        float: right;
        font-size: .75em;
      }
    }
  }

  .rnew-test-result-info-list {
    margin-top: 35px;

    .badge {
      margin-left: 10px;
    }
  }

  .product-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;

    .product-image {
      max-width: 300px;
    }
  }

  .product-test-date {
    font-size: .875em;
  }

  .battery-progress {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .barcode {
    margin-top: 25px;
    width: 100%;
    border: 0;
    margin-bottom: 25px;
  }

  .accessory-badges,
  .diagnostic-codes-badges {
    display: flex;
    flex-wrap: wrap;

    .badge {
      margin: 0 5px;
    }

    .accesory-badge {
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }

  .legend {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .legend-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: .875em;

      .item {
        display: block;
        width: 32px;
        height: 32px;
        margin-right: 10px;

        &.ok {
          background-color: #c3e6cb;
        }

        &.failure {
          background-color: #f5c6cb;
        }

        &.sram {
          background-color: #bee5eb;
        }

        &.sku {
          background-color: #ffeeba;
        }

        &.none {
          border: 1px solid #dedede;
        }
      }
    }
  }
}

.product-info {
  .controls {
    padding: 0;
  }
}

.accessory-badges {
  font-size: 12px;
  width: 100%;

  .badge {
    font-size: 14px;
  }
}