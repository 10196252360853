.rnew-models-list {
  .photo-box {
    width: 100px;
    display: inline-flex;
    border: 1px solid #dedede;
    border-radius: 5px;
    overflow: hidden;
    height: 100px;
    justify-content: center;
    align-items: center;
    background: #fff;
  }

  .child-model {
    padding-left: 100px;
  }

  .child-models-wrapper {
    margin-bottom: 15px;
    text-align: center;

    .child-models-action {
      margin-top: 5px;
    }
  }

  .badges {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;

    .badge {
      margin-left: 0;
    }
  }
}