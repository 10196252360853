.form-inline {
  .move-to-cart-aisle {
    display: none;
  }
}

.basket-shelf-add-btn {
  margin-left: 10px;
}

.move-cart-form .select2 {
  min-width: 200px;
  margin-right: 10px;
}