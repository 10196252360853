.accessories-in,
.accessories-out {
  display: flex;

  .form-group {
    width: 100%;
    padding: 0 10px;
  }
}

.accessories-in-area,
.accessories-out-area {
  padding: 0 10px;

  .accessories-in-content,
  .accessories-out-content {
    min-height: 130px;
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .accesory-badge {
    margin: 0 5px;
    font-size: 12px;
    margin-top: 15px;

    .badge {
      cursor: pointer;
      margin: 0 5px;
      font-size: 14px;
    }

    &.badge-danger {
      padding: 0 5px;
      height: 2rem;
      background: rgb(189, 0, 0);
      background: linear-gradient(90deg, rgba(189, 0, 0, 1) 0%, rgba(164, 0, 62, 1) 100%);
    }
  }
}

.refurbish {
  button[type="submit"] {
    display: block;
  }
}

.rnew-allegro-extension {
  .refurbish {
    button[type="submit"] {
      display: block;
    }
  }
}

.diagnostic-codes-wrapper {
  display: flex;
  justify-content: center;

  .diagnostic-codes-controls {
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: .750em;

    .rnew-primary-button {
      margin-bottom: 10px;
    }
  }

  .diagnostic-codes-list {
    resize: both;
    min-height: 200px !important;
    min-width: 300px !important;
  }

  .diagnostic-codes-search {
    margin-bottom: 10px;
  }
}

cite.copy {
  cursor: pointer;
}

.print-and-change-location .select2.select2-container {
  min-width: 425px;
}

.chart-wrapper-small {
  width: 35%;
  height: 35vh;
  position: relative;
}

.chart-wrapper-big {
  position: relative;
  width: 100%;
  height: 30vh;
}