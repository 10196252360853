.toast {
  color: #fff;
  padding: 5px 65px;
  margin-top: 35px;
  margin-right: 35px;
  max-width: 500px;
  z-index: 99999;

  .toast-header {
    background-color: transparent;
    color: #fff;
  }

  &.toast-error {
    background: rgb(189, 0, 0);
    background: linear-gradient(90deg, rgba(189, 0, 0, 1) 0%, rgba(164, 0, 62, 1) 100%);
  }

  &.toast-success {
    background: rgb(0, 128, 0);
    background: linear-gradient(90deg, rgba(0, 128, 0, 1) 0%, rgba(0, 164, 144, 1) 100%);
  }

  &.toast-info {
    background: $primaryManColor;
    background: linear-gradient(90deg, $primaryManColor 0%, rgba(1, 73, 88, 1) 100%);
  }

  &.toast-warning {
    background:  rgb(225, 154, 11); 
    background: linear-gradient(90deg, rgba(225, 154, 11, 1) 0%, rgba(219, 155, 26, 1) 100%);
  }
}

.notification {
  display: none;
}

.alert {
  &.alert-info {
    background: $primaryManColor;
    background: linear-gradient(90deg, $primaryManColor 0%, rgba(1, 73, 88, 1) 100%);
  }

  &.alert-error {
    background: rgb(189, 0, 0);
    background: linear-gradient(90deg, rgba(189, 0, 0, 1) 0%, rgba(164, 0, 62, 1) 100%);
  }
}