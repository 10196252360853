.rnew-modal {
  display: none;

  .rnew-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .rnew-modal-container {
      background-color: #fff;
      padding: 30px;
      // max-width: 500px;
      max-height: 90vh;
      border-radius: 4px;
      overflow-y: auto;
      box-sizing: border-box;

      .rnew-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .rnew-modal-title {
          margin-top: 0;
          margin-bottom: 0;
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 1.25;
          color: $secondaryManColor;
          box-sizing: border-box;

          body.woman & {
            color: $secondaryWomanColor;
          }
        }

        .rnew-modal-close {
          background: transparent;
          border: 0;

          &::before {
            content: "\2715";
          }
        }
      }


      .rnew-modal-content {
        // overflow: overlay;
        max-height: 70vh;
        margin-top: 2rem;
        margin-bottom: 2rem;
        line-height: 1.5;
        color: rgba(0, 0, 0, .8);
      }

      .rnew-modal-footer {
        .rnew-modal-btn {
          font-size: .875rem;
          padding: .5rem 1rem;
          background-color: #e6e6e6;
          color: rgba(0, 0, 0, .8);
          border-radius: .25rem;
          border-style: none;
          border-width: 0;
          cursor: pointer;
          -webkit-appearance: button;
          text-transform: none;
          overflow: visible;
          line-height: 1.15;
          margin: 0 10px 0 0;
        }
      }
    }
  }

  &.is-open {
    display: block;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.modal-slide {
  display: none;

  &.is-open {
    display: block;
  }
}

.modal-slide[aria-hidden="false"] {
  .rnew-modal-overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .rnew-modal-container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }
}

.modal-slide[aria-hidden="true"] {
  .rnew-modal-overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .rnew-modal-container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }
}

.modal-slide {
  .rnew-modal-container,
  .rnew-modal-overlay {
    will-change: transform;
  }
}