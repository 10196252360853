

.dropzones-wrapper {
    padding: 15px 10px;
    display: flex;
}
.product-dropzone {
    padding: 20px 10px;
    background-color:white;
    width: 300px;
}

#outer-dropzone {
    height: 140px;
  }
  
  #inner-dropzone {
    height: 80px;
  }
  
  .dropzone {
    background-color: #bfe4ff;
    border: dashed 4px transparent;
    border-radius: 4px;
    // margin: 10px auto 30px;
    padding: 10px;
    width: 85%;
    transition: background-color 0.3s;
  }
  
  .battery-dropzone {
    background-color: grey;
    margin: 10px auto;
    min-height: 81px;
  }
  .drop-active {
    border-color: #aaa;
  }
  
  .drop-target {
    background-color: #29e;
    border-color: #020202;
    border-style: solid;
  }
  
  .battery {
    height: 30px;
    width: 170px;
    display: inline-block;
    min-width: 40px;
    // padding: 2em 0.5em;
    // margin: 1rem 0 0 1rem;
  
    // color: #fff;
    // background-color: #29e;
    border: solid 2px #fff;
  
    touch-action: none;
    transform: translate(0px, 0px);
  
    transition: background-color 0.3s;
  }
  
  .drag-drop.can-drop {
    // color: #000;
    // background-color: #4e4;
  }