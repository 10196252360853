.models.create-form {
  width: 100%;

  .series {
    display: block;
  }

  .case-type {
    display: none;
  }
}