.centered {
  @include rnew-centered-block;
}

.content-wrapper {
  padding: 30px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
}

.card-wrapper {
  width:100%;
  margin-bottom: 25px;

  .card-header {
    margin: 10px 0;
    font-weight: 700;
    color: $secondaryManColor;

    body.woman & {
      color: $secondaryWomanColor;
    }
  }

  .card {
    box-shadow: none;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 15px;

    .card-body {
      h5 {
        margin-bottom: 20px;
      }
    }
  }
}

.hidden {
  display: none;
}

.controls {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  .form-inline {
    margin: 35px 0 35px 0;
  }
}

.rnew-tabs {
  margin-bottom: 20px;

  .rnew-tab-item {
    margin-right: 5px;

    .rnew-tab-link {
      border-radius: 0;
      font-size: .875em;
      color: #000;
      background: #dee2e6;

      &.active {
        background: #fff;
      }
    }
  }
}

.rnew-extension-alert {
  .download-button {
    background: #fff;
    text-decoration: none;
    color: #000;
  }

  h5 {
    color: #fff !important;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.rnew-configuration-alert {
  h5 {
    color: #fff !important;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.rnew-allegro-extension {
  .rnew-extension-alert {
    display: none;
  }
}

.jstree-contextmenu {
  font-size: 14px;
}

.rnew-sidebar-content .rnew-sidebar-list .rnew-navbar-link-title {
  .badge {
    position: absolute;
    top: .6rem;
  }
}

.rnew-action {
  color: #000;
  margin-right: 10px;
  text-align: center;

  &.delete {
    color: #ff0000;
  }
}

.changelog-header {
  display: flex;
  flex-direction: column;
  .changelog-date {
    font-style: italic;
    font-size: .6rem;
  }
}
