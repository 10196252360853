.shelf-info {
  .rnew-shelf-info-list {
    list-style-type: none;
    padding: 0;

    .rnew-shelf-info-item {
      margin-bottom: 10px;
      width: 100%;

      .barcode {
        width: 100%;
      }
    }
  }

  .barcode {
    border: 0;
    margin-bottom: 25px;
  }

  .printBtn {
    margin-top: 20px;
  }
}

.products-table {
  width: 100%;
}

.choose-basket {
  margin-right: 10px;
  font-size: .750em;

  .choose-basket-select {
    margin-left: 10px;
    font-size: .750em;
  }
}