.submit-price-source-row-form {
    margin-top: 0.5rem;
    font-size: inherit;
}

.price-source {
    /* background-color: #fd7e14; */
    border-bottom: 2px solid black;
}

.controls {
    .control-date-range {
        margin: -10px 0 0 0;
    }
}

.sku:checked {
    background-color: #fd7e14;
    vertical-align: middle;
}

.child-skus-price-source-form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.child-skus-price-source-form div {
    display: flex;
    margin: 1px 0;

    &.hidden {
        display: none;
    }
    &.--showed {
        display: block;
    }
    a {
        margin-left: 1rem;
    }

    input[type="radio"] {
        margin-right: 3px;
    }
}

.percent-threshold-wrapper .input-group {
    flex-wrap: nowrap;
}

.price-sources-modal .rnew-modal-container {
    width: 95vw;
}