.action-wrapper {
  position: fixed;
  left: 250px;
  right: 0;
  bottom: 0;
  background: #fff;
  height: 140px;
  display: none;
  padding: 35px;
  z-index: 9999;

  .action-wrapper-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;

    .action {
      margin-bottom: 10px;
    }
  }
}