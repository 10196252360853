.rnew-table-wrapper .rnew-table td {
  &.tree {
    text-align: left;
    width: 342px;
  }

  &.delivery-note {
    text-align: left;
  }

  .badge {
    margin-top: 0;
  }
}

#copy-section {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
