.add-product-form {
  .serial-numbers {
    display: none;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .form-check {
    margin: 10px 0;

    .form-check-label {
      margin-left: 10px;
    }
  }
}

.accessory-link {
  cursor: pointer;
}

.change-delivery-wrapper {
  padding: 35px 0;
  margin-top: 10px;
  display: none;
}

.basket-table {
  width: 100%;
}