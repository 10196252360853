.rnew-primary-button {
  background: rgb(246, 93, 44);
  background: linear-gradient(90deg, $secondaryManColor 0%, #ca3a71 100%);
  color: #fff;
  font-size: .875em;
  border: 0;
  margin-bottom: 10px;
  
  body.woman & {
    background: $secondaryWomanColor;
  }

  &:hover,
  &:active {
    background: rgb(246, 93, 44);
    background: linear-gradient(90deg, $secondaryManColor 0%, #ca3a71 100%);

    body.woman & {
      background: $secondaryWomanColor;
    }
  }

  &.active {
    background: $primaryManColor;
    background: linear-gradient(90deg, $primaryManColor 0%, rgba(1, 73, 88, 1) 100%);

    body.woman & {
      background: $primaryWomanColor;
    }
  }
}

.rnew-default-button {
  background: #373737;
  color: #fff;
  font-size: .875em;
  border: 0;
  margin-left: 5px;
}

.rnew-green-button,
.rnew-green-button:focus,
.rnew-green-button:hover {
  background: rgb(0, 128, 0);
  background: linear-gradient(90deg, rgba(0, 128, 0, 1) 0%, rgba(0, 164, 144, 1) 100%);
  color: #fff;
  font-size: .875em;
  border: 0;
  margin-left: 10px;
}

.support-widget-button {
  position: fixed;
  background: #205081;
  padding: 5px;
  border: 2px solid white;
  border-top: none;
  font-weight: bold;
  color: white;
  display: block;
  white-space: nowrap;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  -webkit-box-shadow: 0 0 5px;
  box-shadow: 0 0 5px;
  border-radius: 0 0 5px 5px;
  left: 100%;
  top: 40%;
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: top left;
  -moz-transform: rotate(90deg);
  -moz-transform-origin: top left;
  -ms-transform: rotate(90deg);
  -ms-transform-origin: top left;
  transform: rotate(90deg);
  transform-origin: top left;
}