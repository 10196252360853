.update-form {
  height: 100%;
}

.permissions {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.permissions .form-group {
  background: #eaeaea;
  padding: 10px;
  margin-bottom: 0;
}