.badge {
  font-size: 12px;
  font-family: Open Sans, sans-serif;
  margin-left: 10px;

  &.badge-danger {
    background: rgb(189, 0, 0);
    background: linear-gradient(90deg, rgba(189, 0, 0, 1) 0%, rgba(164, 0, 62, 1) 100%);
  }

  &.badge-info {
    background: $primaryManColor;
    background: linear-gradient(90deg, $primaryManColor 0%, rgba(1, 73, 88, 1) 100%);
  }

  &.badge-success {
    background: rgb(0, 128, 0);
    background: linear-gradient(90deg, rgba(0, 128, 0, 1) 0%, rgba(0, 164, 144, 1) 100%);
  }

  &.badge-delivery-note {
    white-space: wrap !important;
    text-align: left !important;
  }

  &.blink {
    animation: blinker 1s linear infinite;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}