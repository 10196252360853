.rnew-table-wrapper {
  font-size: 12px;
  padding: 0 10px;

  .rnew-table {
    width: 100% !important;
    font-size: 12px;

    .comment-cell {
      text-align: right;
      min-width: 25rem;
      // input {
      //   display: inline-block;
      // }
    }

    td, th {
      vertical-align: middle !important;

      input[type="text"] {
        width: 100%;
      }

      &.action {
        white-space: nowrap;
      }
    }

    th {
      text-align: center;
    }

    td {
      text-align: center;

      p {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .rnew-action {
      color: #000;
      margin: 0 10px;
      text-align: center;

      &.delete {
        color: #ff0000;
      }
    }

    .inactive {
      color: #ccc8c8;
    }
  }

  ul.pagination {
    margin-top: 20px !important;
    margin-bottom: 50px !important;
  }
}

.dataTables_length {
  float: left;
}

.controls a,
.controls button {
  margin-right: 10px;
}

@media print {
  .rnew-table-wrapper {
    .badges {
      display: none;
    }
  }
}

.table {
  tr {
    &.selected {
      background: $primaryManColor !important;
      background: linear-gradient(90deg, $primaryManColor 0%, rgba(1, 73, 88, 1) 100%) !important;
      color: #fff !important;

      td {
        border: 1px solid transparent;
      }

      a {
        color: #fff !important;
      }
    }
  }
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_filter {
  clear: both;
}

div.dataTables_wrapper div.dataTables_filter label {
  justify-content: right !important;
}

div.dataTables_wrapper div.dataTables_length select {
  margin: 0 10px;
}


.no-sort {
  &:before,
  &:after {
    display: none !important;
  }
}

.search {
  width: 100%;
}

.table-responsive {
  position: relative;
  min-height: 400px;
}

tfoot {
  display: table-header-group;
}

.dt-buttons,
.dataTables_info {
  float: left;
}

.dataTables_filter {
  float: right;
}

