.rnew-navbar {
  padding: 0.8125rem 0.5rem;
  border: 0;

  .rnew-badge {
    color: #fff;
    padding: 4px 6px;
    right: 8px;
    top: 15px;
  }
}
