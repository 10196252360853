.sku-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .sku-info-text {
    width: 100%;
    text-align: center;
  }

  h2.sku-info-text,
  p.sku-info-text {
    margin-bottom: 20px;
  }
}