.inventory-analyse-table-wrapper {
  width: 100%;

  .form-check-label {
    margin-top: 3px;
  }
}

.controls-section {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
}

.add-document-row,
.remove-document-row {
  cursor: pointer;
  margin: 0 10px;
}

.remove-document-row {
  color: #ff0000;
}

.document-content,
.document-type,
.document-reason,
.document-url {
  margin-bottom: 20px;
}