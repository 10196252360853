.scanned-product-list {
  display: none;

  .scanned-item-list {
    list-style-type: none;
    padding: 0;

    li {
      padding: 15px;
      background: #f9f9f9;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      cite {
        font-style: normal;
        font-size: 12px;
      }
    }
  }
}

.scan-product-or-shelf-hint {
  display: none;
}
.scan-cart-or-aisle-hint {
  display: none;
}