.deliveries.update-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .update-form {
    height: auto;
  }

  .customization-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
.customization-row,
    .customization-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  background-color: #e9ecef61;
  padding: 5px;
  margin: 5px;
  background-clip: border-box;
  border: 1px solid #000000;
  border-radius: 0.25rem;
  justify-content: space-between;

    .form-group:has(> .customization-quantity) {
          width: 8%;
      }
      .form-group:has(> .customization-model) {
        width: 25%;
    }
    .form-group:has(> .customization-modelText) {
      width: 65%;
  }
  .form-group:has(> .customization-cpu),
  .form-group:has(> .customization-ram),
  .form-group:has(> .customization-hddCapacity),
      .form-group:has(> .customization-other) {
    width: 24%;
}

        .select2.select2-container {
          min-width: 150px;
          margin-left: 0;
          margin-right: 0;
      }

      &.disabled {
        pointer-events: none;
      }
    }

    .customization-list-controls {
      display: flex;

      .customization-list-control {
        padding: 5px;

        .remove {
          display: none;
          font-size: 24px;
          color: #ff0000;
        }

        .remove-all {
          display: none;
          font-size: 24px;
          margin-top: 5px;
        }

        .add {
          font-size: 24px;
          color: #008000;
        }
      }
    }
  }
}
