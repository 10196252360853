.inventory {
  .process-form {
    width: 100%;
  }

  .inventory-scanned-table {
    margin: 10px 0;

    td, th {
      padding: 10px;
    }
  }
}