.form {
  label, legend {
    font-weight: 700;
    font-size: .875em;
    color: #000;
  }

  .form-control {
    border: 1px solid #ced4da;
    background: #f9f9f9;
  }

  .controls {
    padding: 0;
  }

  .input-group {
    align-items: center;
    // flex-wrap: nowrap;
    .input-group-text {
      font-size: 1.5em;
      border: 0;
      background-color: transparent;
      color: #e0e1e1;
      border-right: 1px solid #ced4da;
      border-bottom: 1px solid #ced4da;
      border-top: 1px solid #ced4da;
    }

    .input-group-addon {
      margin-right: 15px;
    }
    
  }

  cite {
    font-weight: 700;
    font-size: .750em;
    font-style: normal;
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
  }

  .alert {
    ul {
      padding: 0;
      list-style-type: none;
      margin: 0;
      font-size: .875em;
    }
  }

  .required {
    &::before {
      content: '*';
      color: #ff0000;
    }
  }

  .form-check {
    .required {
      &::before {
        content: '';
      }
    }
  }

  .form-check {
    margin: 10px;
  }

  .custom-file-label {
    display: block;
  }
}

.form-group.table-danger,
.form-group.table-info,
.form-group.table-success {
  padding: 10px;
}