.models.update-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.models-photos.update-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .form-check {
    margin: 15px 0;
  }

  .update-form {
    height: auto;
  }

  .photos-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 15px;
    margin: 15px 0;
    align-items: flex-end;
    justify-content: center;
    position: relative;

    .input-group-text {
      font-size: 1em;
    }

    .form-group {
      margin-bottom: 0;
    }

    .photo-box {
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
      font-size: 2em;

      &.is-dragover {
        transition: all .2s ease-in-out;
        position: relative;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: "Font Awesome 5 Free", sans-serif;
          font-weight: 900;
          content: '\f093';
          font-size: 2em;
          color: #5e5e5e;
        }

        .photo-image {
          opacity: .2;
        }
      }
    }

    .photo-details {
      margin-bottom: 15px;
      height: 40px;
      width: 100%;

      time {
        clear: both;
        font-size: .875em;
        display: block;
      }
    }

    .photo-image {
      margin-bottom: 15px;
      text-align: center;
      border-radius: 25px;

      &.no-image {
        background: #dedede;
        border-radius: 25px;
        padding: 35px;
      }

      small {
        font-size: 12px;
      }
    }

    .photo-controls {
      position: absolute;
      top: 15px;
      width: 100%;
      z-index: 999;
      height: 21px;

      .photo-badge {
        float: left;
        margin-left: 15px;
      }

      .photo-delete,
      .delete-inserted-photo {
        float: right;
        margin-right: 15px;
        margin-top: -2px;
      }

      .delete-inserted-photo {
        font-size: 16px;
      }
    }

    .file {
      input {
        width: 100%;
      }
    }

    .internal-photos-info {
      margin-top: 15px;
      font-size: .875em;
    }

    .set-cover, .remove-cover {
      color: #fff;
    }
  }

  .file-hint {
    margin-bottom: 0;
    margin-right: 15px;
    font-size: 12px;
  }

  .custom-file-label::after {
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    content: '\f574';
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
    font-family: Font Awesome\ 5 Pro, sans-serif;
    font-weight: 900;
  }
}

.iai-description-card-wrapper, .description-form {
  width: 100%;
}